// Tooltip

.bp4-tooltip2 {
  z-index: 99;
  position: relative;
  .bp4-popover2-content {
    font-size: 10px;
    white-space: nowrap;
    padding: 0;
    background: #fff;
    padding: 4px 6px;
    border-radius: 6px;
    border: 1px solid $gray-light-4;
    transition: background-color $general-change;
    margin: -20px 0 0 -24px;
    line-height: 1.2;
    color: $black-font;
  }
  .bp4-popover2 {
    &-arrow {
      display: none;
    }
  }
}
