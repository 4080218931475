// Step list

.step-list-sidebar {
  position: relative;
  z-index: 2;
  padding: 0;
  min-width: 166px;
  flex: 0 0 auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 4px -2px black;

  .step-list {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 4px;
    padding: 0 4px;
    scrollbar-width: thin;
  }
}

.step-thumbnail {
  height: 120px;
  width: 150px;
  position: relative;
  padding: 0;
  margin: 8px 4px;
  transition: all $general-change;
  border: 2px solid transparent;
  box-shadow: 0 0 0 1px $gray-light-2;

  .background-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.dragging {
    border: 2px solid $gray-4;
    opacity: 0.5;
    .bp3-button {
      display: none;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
    border-radius: 4px;
    -webkit-user-drag: none;
  }

  &:last-child {
    margin-bottom: 4px;
  }

  &:hover {
    box-shadow: 0 0 1px 2px rgba($primary, 0.5);
    cursor: pointer;
  }

  &:active {
    border-color: $primary;
  }

  &.selected {
    border-color: $primary;
    &.dragging {
      box-shadow: 0 0 0 1px $primary;
    }
  }

  &:hover {
    .delete-button,
    .camera-button {
      opacity: 1;
    }
  }

  .thumbnail-actions {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .delete-button,
  .camera-button {
    opacity: 0;
    transition: opacity 0.2s ease-out;
    width: 26px;
    height: 26px;
    margin-left: 4px;
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      background: $gray-light-4;
    }
  }

  .camera-button {
    &.has-camera {
      .bp3-icon,
      svg path {
        color: $warning;
        fill: $warning;
      }
    }
  }
}

.drag-drop-box {
  position: relative;

  .drop-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;

    &.dragging {
      display: block;
      background: rgba(0, 0, 0, 0.1);
    }

    &.dropping {
      &.drop-high {
        box-shadow: inset 0px 50px 50px -50px gainsboro;
      }
      &.drop-low {
        box-shadow: inset 0px -50px 50px -50px gainsboro;
      }
    }
  }
}

// fix overlapping scrollbar in firefox
@-moz-document url-prefix() {
  .step-list {
    &.has-scroll {
      padding-right: 14px;
    }
  }
}
